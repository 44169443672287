import React from "react";
import { Helmet } from "react-helmet-async";
import styled from "styled-components";
import LoginModule from "app/components/LoginModule";
import { useAutoLogin } from "app/routes/Login/hooks";

const Wrapper = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100%;
`;

export default function Login() {
  // @TODO show auto-login error notice? Currently just removes token from QS.
  useAutoLogin();

  return (
    <Wrapper>
      <Helmet title="Login | Online Dance Classes and Tutorials" />
      <LoginModule />
    </Wrapper>
  );
}
